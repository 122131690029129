import { useState } from 'react';
import './App.css';
import { addMetaverseRequest } from './service';

function App() {
  const emailRe = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\.[\d]{1,3}\])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [nameError, setNameError] = useState('')
  const [phoneError, setPhoneError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [submit, setSubmit] = useState(false)

  function isEmail(email: string) {
    if (!email) return false
    return emailRe.test(email.toLowerCase())
  }

  function valiadteInput(type: string) {
    if (type === 'name') {
      if (!name?.trim()) {
        setNameError('Name Required')
      }
    }
    if (type === 'phone') {
      const phoneN = phone.trim()
      setPhone(phoneN)
      if (phoneN) {
        if (phoneN?.length > 10 || phoneN.length < 10) {
          setPhoneError('Invalid Phone Number')
        }
        else if (!/^[\d]+$/.test(phoneN)) {
          setPhoneError('Invalid Phone Number')
        }
      }
    }
    if (type === 'email') {
      if (!email?.trim()) {
        setEmailError('Email Required')
      }
      else if (!isEmail(email)) {
        setEmailError('Invalid Email ID')
      }
    }
  }

  function handleName(val: string) {
    setNameError('')
    setName(val)
  }

  function handlePhone(val: string) {
    setPhoneError('')
    setPhone(val)
  }

  function handleEmail(val: string) {
    setEmailError('')
    setEmail(val.toLowerCase())
  }

  function handleSubmit() {
    if (!name.trim() || !email.trim()) {
      valiadteInput('name')
      valiadteInput('phone')
      valiadteInput('email')
    } else {
      addMetaverseRequest({
        name: name.trim(),
        phone: phone.trim(),
        email: email.trim()
      }).then(res => {
        if (res.status) {
          setSubmit(true)
        }
      })
    }
  }

  return (
    <div className="main">
      <header className="main-header">
        <img src="/logo.svg" className="" alt="BattleXO Logo" />
      </header>
      <div className="form-wrapper">
        {
          submit ?
            <>
              <div className='success'>
                <img src="/done.gif" height={90} width={90} alt="Done" />
                <p>Congratulations for</p>
                <h2>XO META EARLY ACCESS</h2>
                <p className='cta'>To know more visit - <a href="https://battlexo.com?utm_campaign=xometa">Battlexo</a></p>
              </div>
            </>
            :
            <>
              <h1 className='card-heading'>Pre - Register for XO Meta</h1>
              <div className="form-control-wrapper">
                <div className="form-control">
                  <label htmlFor="name">Name<span className='required'>*</span></label>
                  <input
                    id='name'
                    type="text"
                    value={name}
                    onChange={ev => handleName(ev.target.value)}
                    onBlur={() => valiadteInput('name')}
                  />
                  <p className='error'>{nameError}</p>
                </div>
                <div className="form-control">
                  <label htmlFor="email">Email<span className='required'>*</span></label>
                  <input
                    id='email'
                    type="text"
                    value={email}
                    onChange={ev => handleEmail(ev.target.value)}
                    onBlur={() => valiadteInput('email')}
                  />
                  <p className='error'>{emailError}</p>
                </div>
                <div className="form-control">
                  <label htmlFor="phone">Phone Number</label>
                  <input
                    id='phone'
                    type="text"
                    value={phone}
                    onChange={ev => handlePhone(ev.target.value)}
                    onBlur={() => valiadteInput('phone')}

                  />
                  <p className='error'>{phoneError}</p>
                </div>
                <button className='submit' onClick={handleSubmit}>Register</button>
              </div>
            </>
        }
      </div>
    </div>
  );
}

export default App;
